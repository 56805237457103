import React from 'react'
import { graphql, Link } from 'gatsby'
// import MDXRenderer from "gatsby-mdx/mdx-renderer";
import TitlePage from '../components/TitlePage'
import SEO from '../components/seo'

import * as S from '../components/Content/styled'
import Section from '../components/Section'
import Heading from '../components/Heading'
import LocalizedLink from '../components/LocalizedLink'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import FacebookIcon from '../assets/icons/ico_facebook.svg'
import LinkedInIcon from '../assets/icons/ico_LinkedIn.svg'
import YoutubeIcon from '../assets/icons/ico_YouTube.svg'
import useTranslations from '../components/useTranslations'

const getCategoryCountByName = categories => {
    return categories.reduce((prev, { node: { name } }) => {
        prev[name] = (prev[name] || 0) + 1
        return prev
    }, {})
}

const MdxBlockquote = ({ href, ...props }) => <></>

const Post = props => {
    const post = props.data.post
    const { pages: { blog } } = useTranslations()

    const featuredPosts = props.data.featuredPosts.edges || []
    const allCategories = props.data?.allBlogCategoryNode.edges || []
    const allCategoryCounts = getCategoryCountByName(
        props.data?.allBlogCategoryCountNode.edges || []
    )
    const metaDescription = post.frontmatter.metaDescription ? post.frontmatter.metaDescription : post.frontmatter.description

    return (
        <>
            <SEO
                title={post.frontmatter.title}
                description={metaDescription}
                image={post.frontmatter.image}
            />
            <Section className={'py-12 text-center mx-auto'}>
                <LocalizedLink
                    to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}
                    className={
                        'text-ink-60 font-medium'
                    }
                >
                    <Heading type="h2" className={'w-5/6 mx-auto mb-4'}>
                        {post.frontmatter.title}
                    </Heading>
                </LocalizedLink>
                <div className={'text-ink-60 font-medium'}>
                    <span className={'mr-4 text-ink-60'}>
                        {post.frontmatter.category}
                    </span>
                    <span>{post.frontmatter.author}</span>
                </div>
            </Section>
            <Section>
                <div className={'grid lg:grid-cols-3 lg:gap-12'}>
                    <div className={'col-span-2'}>
                        <div className="flex justify-center mb-8">
                            <img
                                src={post.frontmatter.featuredimage}
                                alt=""
                                className="w-full object-contain"
                            />
                        </div>
                        <div className="text-ink-60 text-lg post-body mb-8">
                            <MDXRenderer
                                components={{
                                    a: MdxBlockquote,
                                }}
                            >
                                {post.body}
                            </MDXRenderer>
                        </div>
                        <div className="flex">
                            {/*<div className={'flex items-center gap-4'}>*/}
                            {/*    <p className={'font-medium text-ink-100'}>*/}
                            {/*        Tagy:*/}
                            {/*    </p>*/}
                            {/*    <div*/}
                            {/*        className={*/}
                            {/*            'py-1.5 px-4 bg-ink-10 rounded text-ink-60'*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        Živnosť*/}
                            {/*    </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className={'flex space-x-8 flex-1 justify-end'}*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        src={FacebookIcon}*/}
                            {/*        className={'w-6 h-6'}*/}
                            {/*        alt=""*/}
                            {/*    />*/}
                            {/*    <img*/}
                            {/*        src={LinkedInIcon}*/}
                            {/*        className={'w-6 h-6'}*/}
                            {/*        alt=""*/}
                            {/*    />*/}
                            {/*    <img*/}
                            {/*        src={YoutubeIcon}*/}
                            {/*        className={'w-6 h-6'}*/}
                            {/*        alt=""*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={''}>
                        {/*<div className={''}>Search</div>*/}
                        <div className={''}>
                            <Heading type="h5" className={'mb-4 font-semibold'}>
                                {blog.featuredPosts}
                            </Heading>
                            <div className={'space-y-6'}>
                                {featuredPosts.map(({ node: post }) => (
                                    <div>
                                        <div>
                                            <p
                                                className={
                                                    'text-sm text-ink-60 opacity-50 mb-1'
                                                }
                                            >
                                                {post.frontmatter.date}
                                            </p>
                                            <LocalizedLink
                                                to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}
                                                className={
                                                    'text-ink-60 font-medium'
                                                }
                                            >
                                                {post.frontmatter.title}
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'mt-16'}>
                            <Heading type="h5" className={'mb-4 font-semibold'}>
                                {blog.similarPosts}
                            </Heading>
                            <div className={'space-y-6'}>
                                {featuredPosts.map(({ node: post }) => (
                                    <div>
                                        <div>
                                            <p
                                                className={
                                                    'text-sm text-ink-60 opacity-50 mb-1'
                                                }
                                            >
                                                {post.frontmatter.date}
                                            </p>
                                            <LocalizedLink
                                                to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}
                                                className={
                                                    'text-ink-60 font-medium'
                                                }
                                            >
                                                {post.frontmatter.title}
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'mt-16'}>
                            <Heading type="h5" className={'mb-5 font-semibold'}>
                                {blog.categories}
                            </Heading>
                            <div className={'space-y-6'}>
                                {allCategories.map(({ node: category }) => (
                                    <div
                                        className={
                                            'flex justify-between items-center'
                                        }
                                    >
                                        <LocalizedLink
                                            to={'/'}
                                            className={
                                                'text-ink-60 font-medium'
                                            }
                                        >
                                            {category.name}
                                        </LocalizedLink>
                                        <p
                                            className={
                                                'text-sm text-ink-60 opacity-50 mb-1'
                                            }
                                        >
                                            ({allCategoryCounts[category.name]})
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}

export const query = graphql`
    query Post($locale: String = "sk", $slug: String!) {
        post: mdx(
            frontmatter: { locale: { eq: $locale }, slug: { eq: $slug } }
        ) {
            body
            frontmatter {
                locale
                title
                description
                metaDescription
                featuredimage
                category
                author
                slug
            }
        }
        featuredPosts: allMdx(
            filter: { frontmatter: { locale: { eq: $locale } } }
            sort: { order: ASC, fields: frontmatter___date }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        slug
                        locale
                        date(formatString: "DD.MM.YYYY")
                    }
                }
            }
        }

        allBlogCategoryNode(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    slug
                    locale
                }
            }
        }

        allBlogCategoryCountNode {
            edges {
                node {
                    locale
                    name
                    count
                }
            }
        }
    }
`

export default Post
